import React from 'react'
import { createRoot } from 'react-dom/client'

import App from 'App'
import AppProviders from 'Context'

import reportWebVitals from './reportWebVitals'

import Schema from 'async-validator'
;(Schema as any).warning = function () {} // disable antd's/async-validator logs

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('app')!
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <AppProviders>
            <App />
        </AppProviders>
        ,
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
