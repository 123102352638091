import { Row, Spin } from 'antd'

const styles = {
    container: { minHeight: '100vh' },
}

export default function CenterLoader() {
    return (
        <Row
            justify="center"
            align="middle"
            style={styles.container}
            className="centerLoader"
        >
            <Spin size="large" />
        </Row>
    )
}
