const baseColors = {
    white: '#FFFFFF',
    transparent: 'transparent',
    // blue shades
    blue1: '#E9F3FA',
    blue2: '#CAE3F3',
    blue3: '#A0CDE9',
    blue4: '#74B5DE',
    blue5: '#4B9FD4',
    blue6: '#238ACB',
    blue7: '#1E75AD',
    blue8: '#196290',
    blue9: '#144F74',
    blue10: '#103E5B',
    blue11: '#092333',
    blue12: '#134D71',
    blue13: '#56BEEC',
    // colors
    blue100: '#F7FCFF',
    blue200: '#CEECFF',
    blue300: '#91C9ED',
    blue400: '#4CA6E0',
    blue500: '#1E75AD',
    blue600: '#105F92',
    blue700: '#054874',
    blue800: '#023657',
    blue900: '#001F33',
    //
    grey2: '#99A6AE',
    grey4: '#EBF3F8',
    grey100: '#F8F8F9',
    grey200: '#E6E8E8',
    grey300: '#A3ADB3',
    grey400: '#63727C',
    grey500: '#354B59',
    grey600: '#223744',
    grey700: '#0F202B',
    grey800: '#081721',
    grey900: '#00090E',
    grey1000: '#7F7F7F',
    grey1100: '#BFBFBF',
    //
    orange100: '#FFF9F0',
    orange200: '#FFEDD1',
    orange300: '#FFCB7D',
    orange400: '#FFAE34',
    orange500: '#E38900',
    orange600: '#BD7200',
    orange700: '#7E4C00',
    orange800: '#3E2500',
    orange900: '#211400',
    orange1000: '#C87014',
    orange1100: '#E37F00',
    //
    red100: '#FFF8F6',
    red200: '#FFDCD4',
    red300: '#F88167',
    red400: '#F36748',
    red500: '#D34323',
    red600: '#B62B0D',
    red700: '#8E1B01',
    red800: '#5B1000',
    red900: '#320900',
    red: '#FF4D4F',
    //
    green100: '#F6FFF8',
    green200: '#CCF9D9',
    green300: '#7EF29E',
    green400: '#50F17E',
    green500: '#23D354',
    green600: '#08A534',
    green700: '#037724',
    green800: '#004514',
    green900: '#01220A',
    //
    purple: '#58508D',
    violet: '#BC5090',
    beige: '#FFF1C9',
    //
    border1: '#F5F7F8',
    border2: '#EFF0F0',
}

const themeShared = {
    space: [
        '0px', // 0
        '4px', // 1
        '8px', // 2
        '12px', // 3
        '16px', // 4
        '18px', // 5
        '24px', // 6
        '32px', // 7
        '48px', // 8
        '56px', // 9
        '64px', // 10
        '100px', // 11
    ],
    fontSizes: [
        '10px', // 0
        '12px', // 1
        '14px', // 2
        '16px', // 3
        '18px', // 4
        '20px', // 5
        '24px', // 6
        '32px', // 7
        '40px', // 8
    ],
    lineHeights: [
        '12px', // 0
        '16px', // 1
        '18px', // 2
        '20px', // 3
        '22px', // 4
        '24px', // 5
        '26px', // 6
        '32px', // 7
        '40px', // 8
    ],
    fontWeight: [
        100, // Thin
        200, // Ultra Light
        300, // Light
        400, // Regular
        500, // Medium
        600, // Semibold
        700, // Bold
        800, // Heavy
        900, // Black
    ],

    radii: {
        default: '8px',
        large: '16px',
    },

    iconSizes: [],

    baseColors,
}

const lightThemeColors = {
    boxShadowDefault: '0px 4px 24px 0px rgba(14, 82, 121, 0.08)',
    boxShadowDashboard: '0px 8px 24px 0px rgba(14, 82, 121, 0.12)',
    generalLayoutBG: baseColors.blue100,
    containersBgPrimary: baseColors.white,
    textPrimary: baseColors.grey800,
    textSecondary: baseColors.grey400,
    border2: baseColors.border2,
    anchorColor: baseColors.blue500,
    disabledIconColor: baseColors.grey300,
    sideMenuBorder: baseColors.border1,
    orangeText: baseColors.orange600,
    button: {
        primary: {
            text: baseColors.white,
            background: baseColors.blue500,
            hoverBg: baseColors.blue400,
            disabledBg: baseColors.blue200,
        },
        subtle: {
            text: baseColors.blue600,
            background: baseColors.blue200,
            hoverBg: baseColors.blue300,
            disabledText: baseColors.blue300,
        },
        outlined: {
            text: baseColors.blue500,
            background: baseColors.white,
            borderColor: baseColors.blue500,
            hoverText: baseColors.blue400,
            hoverBorder: baseColors.blue400,
            disabledText: baseColors.blue300,
            disabledBorder: baseColors.blue300,
        },
        textButton: {
            background: baseColors.transparent,
            text: baseColors.blue500,
            hoverText: baseColors.blue400,
            disabledText: baseColors.blue300,
        },
        roundButton: {
            deleteBg: baseColors.red200,
            deleteFill: baseColors.red500,
            editBg: baseColors.blue200,
            editFill: baseColors.blue500,
            piechartBg: baseColors.green200,
            piechartFill: baseColors.green600,
        },
    },
    menuItem: {
        bgColor: baseColors.transparent,
        text: baseColors.grey400,
        hoverText: baseColors.blue400,
        disabledText: baseColors.blue300,
        disabledIconColor: baseColors.grey300,
        selectedText: baseColors.blue500,
        selectedBgColor: baseColors.blue100,
        selectedIconColor: baseColors.blue500,
        userLabelColor: baseColors.grey800,
        userBlockBorder: baseColors.grey200,
    },
    input: {
        text: baseColors.grey800,
        placeholderText: baseColors.grey300,
        borderDefaultColor: baseColors.grey200,
        borderFocusColor: baseColors.blue500,
        errorColor: baseColors.red500,
        labelColor: baseColors.grey800,
        infoIconDefaultColor: baseColors.blue300,
        infoIconHoverColor: baseColors.blue500,
        asteriskColor: baseColors.red500,
        bgColorDisabled: baseColors.grey100,
        colorDisabled: baseColors.grey300,
        selectClearColor: baseColors.grey400,
    },
    table: {
        headersBg: baseColors.blue100,
        headersColor: baseColors.grey300,
        tdColor: baseColors.grey800,
        tdHoverColor: baseColors.grey4,
        borderColor: baseColors.border2,
        pageSelectedBg: baseColors.blue500,
        pageHoverBg: baseColors.blue300,
        emissionsColor: baseColors.blue500,
        collapseSectionBg: baseColors.blue100,
        collapseSectionBorder: baseColors.blue200,
        collapseSectionLabelColor: baseColors.grey400,
    },
    tabs: {
        text: baseColors.grey400,
        selectedText: baseColors.blue500,
    },
    dashboard: {
        datesColor: baseColors.grey400,
        iconsFill: baseColors.blue500,
        valuesText: baseColors.blue500,
    },
    overviewGraph: {
        statText: baseColors.blue500,
        statBg: baseColors.blue200,
        infoIconColor: baseColors.blue300,
        graphColors: {
            businessProcess: baseColors.blue700,
            consultingAgency: baseColors.orange500,
            customerCommunications: baseColors.purple,
            leadSupply: baseColors.red,
            printSolutions: baseColors.violet,
            workplaySolutions: baseColors.beige,
        },
    },
    chartBreakdown: [
        baseColors.blue12,
        baseColors.blue6,
        baseColors.blue13,
        baseColors.orange1000,
        baseColors.orange1100,
        baseColors.grey1000,
        baseColors.grey1100,
    ],
    account: {
        avatarColor: baseColors.blue500,
        roleTagText: baseColors.blue500,
        roleTagBg: baseColors.blue200,
        clientTagText: baseColors.orange700,
        clientTagBg: baseColors.orange200,
    },
}

export const lightTheme = {
    ...themeShared,
    colors: lightThemeColors,
}

export const SIZES = [4, 8, 16, 24, 32]
